<template>
  <div class="relative">
    <div class="view-header pt-1">
      <h2 class="heading-medium">Videos Categories</h2>

      <div
        v-if="loaded"
        class="flex flex-column mt-2">
        <div class="flex">
          <f-button
            theme="light-blue"
            class="ml-auto no-shrink mb-3 mt-2"
            @click.prevent="$bus.$emit('video-category-merge-popup-show', allCategories.length > categories.length ? allCategories : categories)">
            Merge Categories
          </f-button>
          <f-button
            theme="light"
            class="f-btn-add no-shrink mb-3 mt-2 ml-3"
            @click.prevent="$bus.$emit('video-category-popup-show', null, allCategories)">
            Add Category
          </f-button>
        </div>

        <validation-observer
          ref="categories-filters-form"
          tag="form"
          class="search-form flex flex-align-items-center">
          <f-input-wrapper
            prependIcon="search"
            class="mr-2">
            <input
              type="search"
              v-model="filters.search"
              placeholder="Search by Name"/>
          </f-input-wrapper>

          <v-select
            class="mr-2 my-0 category-select-filter"
            :disabled="filters.only_parent"
            :options="allParents"
            v-model="filters.parent_id"
            :reduce="(category) => category.id"
            :searchable="true"
            placeholder="Show sub categories of..."
            label="name">
          </v-select>

          <f-input-wrapper
            :inputType="'checkbox'"
            :class="{'is-disabled': filters.parent_id > 0}">
            <input
              type="checkbox"
              class="small"
              :disabled="filters.parent_id > 0"
              id="only-parent"
              name="only-parent"
              v-model="filters.only_parent" />
            <label for="only-parent">Only top level categories</label>
          </f-input-wrapper>

          <div class="search-form-buttons flex ml-auto">
            <f-button
             theme="primary"
              :big="true"
              class="m2-1 mb-1-ms ml-0-sm text-nowrap"
              @click.prevent="validateFiltersForm">
              Search
            </f-button>

            <f-button
              theme="warning"
              :big="true"
              class="ml-1 text-nowrap"
              :disabled="resetFiltersDisabled"
              @click.prevent="clearFilters">
              Reset filters
            </f-button>
          </div>
        </validation-observer>

      </div>
    </div>

    <div class="mt-4">
      <f-button
        theme="secondary"
        class="mr-2 text-nowrap"
        :disabled="!isOrderChanged"
        @click.prevent="updateItemsOrder">
        Save categories order
      </f-button>
      <f-button
        theme="warning"
        class="text-nowrap"
        :disabled="!isOrderChanged"
        @click.prevent="loadFilteredData">
        Reset order change
      </f-button>
    </div>
    <f-list
      ref="categories-list"
      class="categories-list mt-2"
      :loaded="loaded"
      :listSettings="listSettings"
      :listItems="categories"
      :rowsReordering="rowsReorderingEnabled"
      :sortingSettings="sortingSettings"
      :sortingCallback="sortBy" />

    <pagination
      :class="{'is-hidden': !loaded}"
      :meta-data="paginationMetaData" />

    <div
      v-if="!loaded && !loadError"
      class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>

    <f-alert
      v-if="loadError"
      visible
      class="my-5"
      theme="warning">
      <span>
        Loading data failed.
        <a
          href="javascript:window.location.reload();"
          class="lnk lnk-alert">
          Reload page
        </a>
        to try again.
      </span>
    </f-alert>

    <video-category-popup />
    <video-category-merge-popup />
  </div>
</template>


<script>
import Vue from 'vue';
import debounce from 'lodash.debounce';
import HasFilters from '@/mixins/HasFilters.vue';
import HasPagination from '@/mixins/HasPagination.vue';
import Pagination from '@/components/core/Pagination.vue';
import FormUtils from '@/utils/FormUtils.js';
import ListUtils from '@/utils/ListUtils.js';
import VideoCategoryPopup from '@/components/popups/VideoCategoryPopup.vue';
import VideoCategoryMergePopup from '@/components/popups/VideoCategoryMergePopup.vue';

export default {
  name: 'videos-categories',
  mixins: [
    HasFilters,
    HasPagination
  ],
  components: {
    'pagination': Pagination,
    'video-category-popup': VideoCategoryPopup,
    'video-category-merge-popup': VideoCategoryMergePopup
  },
  computed: {
    allParents () {
      if (!this.allCategories || !this.allCategories.length) {
        return [];
      }

      return this.allCategories.filter(item => item.children && item.children.length > 0);
    },
    resetFiltersDisabled () {
      return this.filters.search === '' && !this.filters.parent_id && !this.filters.only_parent;
    }
  },
  data () {
    return {
      listSettings: [
        {
          title: 'Name',
          variableName: ['name'],
          type: 'String'
        },
        {
          title: '',
          variableName: ['actonsList'],
          type: 'actions',
          items: [
            {
              disabled: false,
              callback: this.editCategory,
              label: 'Edit',
              theme: 'outline'
            },
            {
              disabled: false,
              callback: this.deleteCategory,
              label: 'Delete',
              theme: 'outline-danger'
            }
          ]
        }
      ],
      sortingSettings: {
        id: null,
        name: null
      },
      categories: [],
      allCategories: [],
      order_by: 'ordering',
      order_direction: 'ASC',
      loaded: false,
      loadError: false,
      filters: {
        only_parent: false,
        parent_id: 0,
        search: ''
      },
      rowsReorderingEnabled: true,
      isOrderChanged: false
    }
  },
  mounted () {
    this.$bus.$on('videos-categories-reload', this.loadFilteredData);
    this.$bus.$on('list-order-changed', this.showOrderChanged);
    this.loadFilteredData();
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      let requestParams = {
        is_pagination: 1,
        page: this.currentPage
      };

      let nofilters = true;
      this.rowsReorderingEnabled = true;

      if (this.order_by && this.order_direction) {
        requestParams.order_by = this.order_by;
        requestParams.order_direction = this.order_direction;
      }

      if (this.filters.search && this.filters.search.length) {
        requestParams.search = this.filters.search;
        nofilters = false;
        this.rowsReorderingEnabled = false;
      }

      if (this.filters.parent_id) {
        requestParams.parent_id = this.filters.parent_id;
        nofilters = false;
        this.rowsReorderingEnabled = true;
      }

      if (this.filters.only_parent) {
        requestParams.only_parent = this.filters.only_parent;
        nofilters = false;
        this.rowsReorderingEnabled = true;
      }

      ListUtils.loadItemsData(this, {
        endpoint: '/api/video/category/get-all',
        method: 'get',
        listField: 'categories',
        params: requestParams,
        successAction: (response) => {
          this.isOrderChanged = false;

          if (nofilters) {
            Vue.set(this, 'allCategories', response.data.data);
          }
        }
      });
    },
    editCategory (item) {
      if (item.id) {
        this.$bus.$emit('video-category-popup-show', item, this.allCategories);
      }
    },
    deleteCategory (item) {
      if (item.id) {
        this.removeInProgress = true;

        ListUtils.removeItem(this, {
          endpointBase: '/api/video/category/remove/',
          id: item.id,
          successTitle: 'Category delted',
          successText: 'Selected category has been deleted',
          confirmTitle: 'Are you sure you want to delete this category?',
          confirmText: 'This operation cannot be undone',
          confirmButtonColor: '#E0211B',
          errorTitle: 'An error has occurred',
          errorText: 'Deletion of selected category has failed. Please try again.',
          successAction: () => {
            this.removeInProgress = false;
            this.loadFilteredData();
          },
          cancelAction: () => {
            this.removeInProgress = false;
          }
        });
      }
    },
    updateItemsOrder () {
      let formDataToSend = this.prepareFormDataToSend();

      FormUtils.submit(this, {
        endpoint: '/api/video/category/set-multiple-ordering',
        formData: formDataToSend,
        successTitle: 'Categories reordered',
        successText: 'Categories new order has been saved',
        errorTitle: 'An error has occurred',
        errorText: 'Modification of categories order has failed. Please try again.',
        successAction: () => {
          this.submitInProgress = false;
          this.loadFilteredData();
        },
        failAction: () => {
          this.submitInProgress = false;
        }
      });
    },
    prepareFormDataToSend () {
      let categoriesList = this.$refs['categories-list'].getLocalItemsList();
      let categoriesForSave = JSON.parse(JSON.stringify(categoriesList.map((item, index) => {
        let childrenArr = [];
        if (item.children.length) {
          for (let i = 0; i < item.children.length; i++) {
            childrenArr.push({
              name: item.children[i].name,
              id: item.children[i].id,
              new_ordering: i
            })
          }
        }

        let obj = {
          name: item.name,
          id: item.id,
          new_ordering: this.categories[index].ordering,
          children: childrenArr
        };
        return obj;
      })));

      return {
        categories: categoriesForSave
      }
    },
    sortBy (fieldName, direction) {
      Vue.set(this, 'order_by', fieldName);
      Vue.set(this, 'order_direction', direction);

      if (fieldName === 'name') {
        Vue.set(this.sortingSettings, fieldName, direction);
        Vue.set(this.sortingSettings, 'id', null);
      } else {
        Vue.set(this.sortingSettings, fieldName, direction);
        Vue.set(this.sortingSettings, 'name', null);
      }

      this.loadFilteredData();
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['categories-filters-form'], this.debouncedLoadFilteredData);
    },
    clearFilters () {
      this.loaded = false;
      this.loadError = false;
      this.filters = {
        parent_id: 0,
        only_parent: false,
        search: ''
      };

      this.sortingSettings = {
        id: null,
        name: null
      };

      this.order_by = 'ordering';
      this.order_direction = 'ASC';

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
    showOrderChanged () {
      this.isOrderChanged = true;
    }
  },
  beforeDestroy () {
    this.$bus.$off('videos-categories-reload', this.loadFilteredData);
    this.$bus.$off('list-order-changed', this.showOrderChanged);
  }
}
</script>

<style lang="scss" scoped>
.category-select-filter {
  min-width: 280px;
}
</style>

<template>
  <f-modal
    ref="video-category-merge-popup-modal"
    :class="{ 'is-visible': isVisible }"
    @close="handleClose">
    <f-modal-header>
      <h3 class="heading-medium">Merge categories</h3>
    </f-modal-header>

    <f-modal-body>
      <validation-observer
        ref="video-category-merge-popup-form"
        tag="form"
        @keydown.enter.prevent>
        <div class="flex">
          <div class="form-group half-width mr-1 mb-2">
            <label for="source-category">Source Category</label>

            <validation-provider
              rules="required"
              name="ć-category"
              v-slot="{ errors }">
              <v-select
                class="mb-0"
                :options="allCategories"
                v-model="sourceCategory"
                :searchable="true"
                placeholder="Select source category"
                label="name">
              </v-select>
              <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
          </div>

          <div class="form-group half-width mb-2">
            <label for="source-child-category">Sub Category</label>

            <v-select
              class="mb-0 ml-1"
              :disabled="!sourceCategory || !sourceCategory.children.length"
              :options="!sourceCategory ? [] : sourceCategory.children"
              v-model="sourceCategoryChild"
              :searchable="true"
              placeholder="Select source sub category"
              label="name">
            </v-select>
          </div>
        </div>

        <div class="flex">
          <div class="form-group half-width mr-1 mb-2">
            <label for="destination-category">Destination Category</label>

            <validation-provider
              rules="required"
              name="destination-category"
              v-slot="{ errors }">
              <v-select
                class="mb-0"
                :options="destinationCategoriesOptions"
                v-model="destinationCategory"
                :searchable="true"
                placeholder="Select destination category"
                label="name">
              </v-select>
              <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
          </div>

          <div class="form-group half-width mb-2">
            <label for="destination-child-category">Sub Category</label>

            <validation-provider
              :rules="sourceCategoryChild ? 'required' : ''"
              name="destination-child-category"
              v-slot="{ errors }">
                <v-select
                  class="mb-0 ml-1"
                  :disabled="!destinationCategory || !sourceCategoryChild || !destinationCategory.children.length"
                  :options="destinationChildCategoriesOptions"
                  v-model="destinationCategoryChild"
                  :searchable="true"
                  placeholder="Select destination sub category"
                  label="name">
                </v-select>
              <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
          </div>
        </div>

      </validation-observer>

    </f-modal-body>
    <f-modal-footer>
      <div class="flex flex-justify-content-between">
        <f-button
          class="ml-0 btn btn-danger"
          theme="danger"
          @click.prevent="handleClose">
          Cancel
        </f-button>
        <f-button
          class="ml-auto btn btn-primary"
          theme="primary"
          :disabled="submitInProgress"
          @click.prevent="validateForm">
          <div
            v-if="submitInProgress"
            class="loading-spinner"
            role="status">
          </div>
          Save changes
        </f-button>
      </div>
    </f-modal-footer>
  </f-modal>
</template>
<script>
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'video-category-merge-popup',
  computed: {
    destinationCategoriesOptions () {
      if (this.sourceCategoryChild) {
        return this.allCategories.filter((item) => item.children.length);
      }

      if (this.sourceCategory) {
        return this.allCategories.filter((item) => item.id !== this.sourceCategory.id);
      }

      return this.allCategories;
    },
    destinationChildCategoriesOptions () {
      if (!this.destinationCategory) {
        return [];
      }

      if (this.sourceCategoryChild) {
        return this.destinationCategory.children.filter((item) => item.id !== this.sourceCategoryChild.id)
      }

      return this.destinationCategory.children;
    }
  },
  watch: {
    sourceCategory (newValue) {
      if (!newValue) {
        this.sourceCategoryChild = null
      }
    },
    destinationCategory (newValue) {
      if (!newValue) {
        this.destinationCategoryChild = null
      }
    }
  },
  data() {
    return {
      sourceCategory: null,
      sourceCategoryChild: null,
      destinationCategory: null,
      destinationCategoryChild: null,
      allCategories: [],
      isVisible: false,
      editMode: false,
      submitInProgress: false
    };
  },
  mounted() {
    this.$bus.$on('video-category-merge-popup-show', this.openPopup);
  },
  methods: {
    handleClose() {
      document.body.classList.remove('no-scroll');
      this.isVisible = false;
    },
    openPopup(allCategories) {
      this.allCategories = allCategories;

      this.sourceCategory = null;
      this.sourceCategoryChild = null;
      this.destinationCategory = null;
      this.destinationCategoryChild = null;

      setTimeout(() => {
        this.isVisible = true;
        this.submitInProgress = false;
        document.body.classList.add('no-scroll');

        if (this.$refs['video-category-merge-popup-form']) {
          this.$refs['video-category-merge-popup-form'].reset();
        }
      }, 0);
    },
    validateForm () {
      FormUtils.validate(this.$refs['video-category-merge-popup-form'], this.submitForm);
    },
    submitForm () {
      let formDataToSend = this.prepareFormDataToSend();
      let endpointUrl = '/api/video/category/merge';

      FormUtils.submitWithConfirm(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Categories merged',
        successText: 'Selected categories have been merged',
        confirmTitle: 'Are you sure you want to merge these categories?',
        confirmText: 'This operation cannot be undone',
        confirmButtonText: 'Yes, merge',
        errorTitle: 'An error has occurred',
        errorText: 'Category merge has failed. Please try again.',
        successAction: () => {
          this.$bus.$emit('videos-categories-reload');
          this.handleClose();
        }
      });
    },
    prepareFormDataToSend () {
      if (this.sourceCategoryChild) {
        return {
          source_category_id: this.sourceCategoryChild.id,
          destination_category_id: this.destinationCategoryChild.id
        }
      }

      return {
        source_category_id: this.sourceCategory.id,
        destination_category_id: this.destinationCategory.id
      };
    }
  },
  beforeDestroy () {
    this.$bus.$on('video-category-merge-popup-show', this.openPopup);
  }
}
</script>

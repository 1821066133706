<template>
  <f-modal
    ref="video-category-popup-modal"
    :class="{ 'is-visible': isVisible, 'modal-with-popup': true }"
    @close="handleClose">
    <f-modal-header>
      <h3 class="heading-medium">
        <template v-if="editMode">
          Edit Category
        </template>
        <template v-else>
          New Category
        </template>
      </h3>
    </f-modal-header>

    <f-modal-body>
      <validation-observer
        ref="video-category-popup-form"
        tag="form"
        @keydown.enter.prevent>

        <div class="form-group">
          <label for="name">
            Name
          </label>

          <validation-provider
            rules="required"
            name="name"
            v-slot="{ errors }">
            <input
              class="full-width"
              type="text"
              v-model="data.name"
              :state="errors.length ? 'invalid' : null"/>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>

        <div class="form-group">
          <label for="parent-category">Parent category</label>

          <validation-provider
            name="parent-category"
            v-slot="{ errors }">
            <div
              :class="{
                'form-select': true,
                'is-invalid': errors.length
              }">
              <v-select
                class="mb-0"
                :options="filteredCategories"
                v-model="data.parent_id"
                :reduce="(category) => category.id"
                :searchable="true"
                placeholder="Select parent category"
                label="name">
              </v-select>
            </div>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>

      </validation-observer>

    </f-modal-body>
    <f-modal-footer>
      <div class="flex flex-justify-content-between">
        <f-button
          class="ml-0 btn btn-danger"
          theme="danger"
          @click.prevent="handleClose">
          Cancel
        </f-button>
        <f-button
          class="ml-auto btn btn-primary"
          theme="primary"
          :disabled="submitInProgress"
          @click.prevent="validateForm">
          <div
            v-if="submitInProgress"
            class="loading-spinner"
            role="status">
          </div>
          <template v-if="editMode">Save changes</template>
          <template v-else>Add</template>
        </f-button>
      </div>
    </f-modal-footer>
  </f-modal>
</template>
<script>
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'video-category-popup',
  computed: {
    filteredCategories () {
      return this.allCategories.filter((item) => (item.id !== this.data.id) && !item.parent_id);
    }
  },
  data() {
    return {
      data: {
        id: 0,
        name: '',
        parent_id: 0
      },
      allCategories: [],
      isVisible: false,
      editMode: false,
      submitInProgress: false
    };
  },
  mounted() {
    this.$bus.$on('video-category-popup-show', this.openPopup);
  },
  methods: {
    handleClose() {
      document.body.classList.remove('no-scroll');
      this.isVisible = false;
    },
    openPopup(category = null, allCategories = []) {
      this.allCategories = allCategories;
      this.editMode = !!category;

      if (this.editMode) {
        this.data.id = category.id;
        this.data.name = category.name;
        this.data.parent_id = category.parent_id;
      } else {
        this.data.id = 0;
        this.data.name = '';
        this.data.parent_id = null;
      }

      setTimeout(() => {
        this.isVisible = true;
        this.submitInProgress = false;
        document.body.classList.add('no-scroll');

        if (this.$refs['video-category-popup-form']) {
          this.$refs['video-category-popup-form'].reset();
        }
      }, 0);
    },
    validateForm () {
      FormUtils.validate(this.$refs['video-category-popup-form'], this.submitForm);
    },
    submitForm () {
      let formDataToSend = this.prepareFormDataToSend();
      let endpointUrl = this.editMode ? '/api/video/category/update/' + this.data.id : '/api/video/category/add';

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Category saved',
        successText: 'This category has been saved',
        errorTitle: 'An error has occurred',
        errorText: 'Modification of an entry has failed. Please try again.',
        successAction: () => {
          this.$bus.$emit('videos-categories-reload');
          this.handleClose();
        }
      });
    },
    prepareFormDataToSend () {
      return {
        name: this.data.name,
        parent_id: this.data.parent_id ? this.data.parent_id : 0
      };
    },
  },
  beforeDestroy () {
    this.$bus.$on('video-category-popup-show', this.openPopup);
  }
}
</script>

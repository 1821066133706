var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f-modal',{ref:"video-category-popup-modal",class:{ 'is-visible': _vm.isVisible, 'modal-with-popup': true },on:{"close":_vm.handleClose}},[_c('f-modal-header',[_c('h3',{staticClass:"heading-medium"},[(_vm.editMode)?[_vm._v(" Edit Category ")]:[_vm._v(" New Category ")]],2)]),_c('f-modal-body',[_c('validation-observer',{ref:"video-category-popup-form",attrs:{"tag":"form"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v(" Name ")]),_c('validation-provider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.name),expression:"data.name"}],staticClass:"full-width",attrs:{"type":"text","state":errors.length ? 'invalid' : null},domProps:{"value":(_vm.data.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"parent-category"}},[_vm._v("Parent category")]),_c('validation-provider',{attrs:{"name":"parent-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:{
              'form-select': true,
              'is-invalid': errors.length
            }},[_c('v-select',{staticClass:"mb-0",attrs:{"options":_vm.filteredCategories,"reduce":function (category) { return category.id; },"searchable":true,"placeholder":"Select parent category","label":"name"},model:{value:(_vm.data.parent_id),callback:function ($$v) {_vm.$set(_vm.data, "parent_id", $$v)},expression:"data.parent_id"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])],1),_c('f-modal-footer',[_c('div',{staticClass:"flex flex-justify-content-between"},[_c('f-button',{staticClass:"ml-0 btn btn-danger",attrs:{"theme":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.handleClose.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('f-button',{staticClass:"ml-auto btn btn-primary",attrs:{"theme":"primary","disabled":_vm.submitInProgress},on:{"click":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[(_vm.submitInProgress)?_c('div',{staticClass:"loading-spinner",attrs:{"role":"status"}}):_vm._e(),(_vm.editMode)?[_vm._v("Save changes")]:[_vm._v("Add")]],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }